import React from 'react'

export class Todo extends React.Component {
    state = {
        complete: this.props.complete
    }

    handleChange = _event => {
        this.setState({
            [_event.target.name]: _event.target.checked
        })
        this.props.toggleToDo({
            id: this.props.id,
            complete: _event.target.checked,
            name: this.props.name
        })
    }

    render() {
        return (
            <div class="single-to-do">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                        checked={this.state.complete}
                        type="checkbox"
                        name="complete"
                        id={this.props.id}
                        onChange={this.handleChange}
                    />
                    <label
                        htmlFor={this.props.id}
                    >
                    </label>
                    <span style={ this.state.complete ? { textDecoration: 'line-through' } : {} }>{ this.props.name }</span>
                </div>
                <button onClick={() => this.props.deleteToDo(this.props.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                </button>
            </div>
        )
    }
}
