import React from 'react'

export class TodoForm extends React.Component {
    state = {
        todoName: ''
        // todoId: 0
    }

    handleChange = _event => {
        this.setState({
            [_event.target.name]: _event.target.value
        })
    }

    handleSubmit = _event => {
        _event.preventDefault()
        if (this.state.todoName !== '') {
            this.props.addToDo({
                name: this.state.todoName,
                complete: false,
                id: Date.now()
            })
            this.setState(state => {
                return {
                    todoName: ''
                    // todoId: state.todoId + 1
                }
            })
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} className="to-do-form">
                <input
                    type="text"
                    name="todoName"
                    autoComplete="off"
                    value={this.state.todoName}
                    onChange={this.handleChange}
                    placeholder="Truc important"
                />
                <button type="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </button>
            </form>
        )
    }
}
