import React from 'react'

class Timer extends React.Component{
    
    amsterdamDate = new Date('June 29, 2019 12:25')
    
    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }
    
    componentDidMount () {
        setInterval(() => {
            let timer = Math.floor((Date.parse(this.amsterdamDate) - Date.now()) / 1000)
            const days = Math.floor(timer / (3600 * 24))
            timer -= days * 3600 * 24
            const hours = Math.floor(timer / 3600)
            timer -= hours * 3600
            const minutes = Math.floor(timer / 60)
            timer -= minutes * 60
            const seconds = timer
            this.setState({
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            })
        }, 1000);
        
    }
    
    render () {
        return <div className="timer">
            <h2> <span>{ this.state.days }</span> jours</h2>
            <h2> <span>{ this.state.hours }</span> heures</h2>
            <h2> <span>{ this.state.minutes }</span> minutes</h2>
            <h2> <span>{ this.state.seconds }</span> secondes</h2>
            <span className="emoji left" role="img">🍺</span>
            <span className="emoji right" role="img">🧀</span>
        </div>
    }
}

export default Timer