import React from 'react'
import { TodoForm } from './TodoForm'
import { Todo } from './Todo'
import base from '../base'

import firebase from 'firebase/app'
import 'firebase/auth'

class List extends React.Component {
    state = {
        todos: [],
        connected: false,
        loaded: false
    }
    
    provider = new firebase.auth.GoogleAuthProvider();

    addToDo = async todo => {
        await this.setState(state => {
            return {
                todos: [todo, ...state.todos]
            }
        })

        // await localStorage.setItem('todos', JSON.stringify(this.state.todos))
    }

    deleteToDo = async _id => {
        await this.setState(state => {
            return { todos: state.todos.filter(todo => todo.id !== _id) }
        })

        // await localStorage.setItem('todos', JSON.stringify(this.state.todos))
    }


    toggleToDo = async _todo => {
        await this.setState(state => {
            return {
                todos: state.todos.map(todo => {
                    if (_todo.id === todo.id) {
                        return {
                            id: _todo.id,
                            name: todo.name,
                            complete: _todo.complete
                        }
                    } else {
                        return todo
                    }
                })
            }
        })
        // await localStorage.setItem('todos', JSON.stringify(this.state.todos))
    }
    
    authHandler = async authData => {
        const store = await base.fetch('todos', { context: this });

        if (store.length) {
            await this.setState({ todos: Object.values(store) })
        }


        this.ref = base.syncState('todos', {
            context: this,
            state: 'todos',
            asArray: true
        })
        await this.setState({
            loaded: true
        })
    }
    
    componentDidMount () {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    connected: true
                })
                this.authHandler({ user });
            }
        });
    }
    
    connect = () => {
        firebase.auth().signInWithPopup(this.provider).then(this.authHandler).catch(function (error) {
            
            console.log(error);
            
        });
    }
    
    componentWillUnmount () {
        base.removeBinding(this.ref)
    }
    
    logOut = () => {
        firebase.auth().signOut().then( () => {
            this.setState({
                connected: false,
                todos: []
            })
        })
    }


    render () {
        if (!this.state.connected) {
            return (
                <div className="list-container">
                    <h2>Liste des trucs à pas oublier</h2>
                    <button className="login" onClick={ this.connect }>Connecte toi avec Google</button>
                </div>
            )
        }
        if (!this.state.loaded) {
            return (
                <div className="list-container">
                    <h2>Liste des trucs à pas oublier</h2>
                    <p style={{fontSize: '2.7rem', textAlign: 'center', marginTop: '3rem'}}>Chargement...</p>
                </div>
            )
        }
        return (
            <div className="list-container">
                <h2>Liste des trucs à pas oublier</h2>
                <button className="logout" onClick={ this.logOut } >Déconnexion</button>
                <TodoForm addToDo={ this.addToDo } />
                <div style={{overflowY: 'auto', maxHeight: '300px'}}>
                    { this.state.todos
                        .map(todo => (
                            <Todo
                                key={ todo.id }
                                id={ todo.id }
                                complete={ todo.complete }
                                name={ todo.name }
                                toggleToDo={ this.toggleToDo }
                                deleteToDo={ this.deleteToDo }
                            />
                        )) }
                </div>
            </div>
        )
    }
}

export default List
