import React from 'react';

import Timer from './components/Timer'
import List from './components/List'

function App() {
  return (
    <div className="App">
        <div className="container">
            <h1>Départ à Amsterdam</h1>
            <Timer/>
        </div>
        <List/>
    </div>
  );
}

export default App;
